<template>
  <v-sheet outlined width="600" class="pa-6 my-4">
    <v-form @submit.prevent="updateItem" ref="form">
      <v-row>
        <v-col>
          <label class="v-label v-label--active theme--light text-body-2"
            >Current account package</label
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
              v-model="form.packageId"
              :items="packages"
              item-value="value"
              item-text="title"
              :rules="[inputRequired]"
              label="Package name"
              :disabled="!canEdit"
              required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
              v-model="form.status"
              :items="statuses"
              item-value="value"
              item-text="title"
              :rules="[inputRequired]"
              label="Package status"
              :disabled="!canEdit"
              required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <vc-date-picker v-if="canEdit" v-model="form.startAt" mode="dateTime" is24hr class="mt-16"
                          :rules="[inputRequired]"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                  :rules="[inputRequired]"
                  label="Start date"
                  :value="inputValue"
                  v-model="startDate"
                  v-on="inputEvents"
                  :disabled="!canEdit"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
          <v-text-field v-else
            :value="startDate"
            label="Start date"
            readonly
            :disabled="true"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <vc-date-picker v-if="canEdit" v-model="form.endAt" mode="dateTime" is24hr class="mt-16"
                          :rules="[inputRequired]" readonly
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                  :rules="[inputRequired]"
                  label="End date"
                  :value="inputValue"
                  v-model="endDate"
                  v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
          <v-text-field v-else
              :value="endDate"
              label="End date"
              readonly
              :disabled="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-alert v-if="hasErrors && !canEdit" type="error" elevation="0" outlined>
        <div v-for="(error, key) in errors" :key="key">
          {{ prepareErrorMessage(error, key) }}
        </div>
      </v-alert>
      <div class="d-flex mt-3">
        <v-btn v-if="!canEdit" type="submit" color="primary" @click="editItem" rounded small> Edit account package </v-btn>
      </div>
      <v-row>
        <div class="d-flex mt-3 ml-3">
          <v-btn v-if="canEdit" type="submit" color="primary" @click="updateItem" rounded small> Confirm </v-btn>
        </div>
        <div class="d-flex mt-3 ml-3">
          <v-btn v-if="canEdit" type="submit" color="default" @click="editItem" rounded small> Cancel </v-btn>
        </div>
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import {FETCH_ACCOUNT_PACKAGE} from "@/store/modules/account/actions";
import {FETCH_PACKAGE_DICTIONARY, UPDATE_ACCOUNT_PACKAGE} from "@/store/modules/account/actions";
import {UPDATED_ACCOUNT_PACKAGE} from "../../../utils/events";
import {EventBus} from "../../../utils";
export default {
  name: "AccountPackageForm",
  props: {
    accountId: {
      required: true,
    },
  },
  data: function () {
    return {
      dataLoading: false,
      canEdit: false,
      form: {
        id: null,
        name: null,
        status: null,
        isActive: null,
        startAt: null,
        endAt: null,
      },
      errors: {},
      statuses: [
      {
        value: 'active',
        title: "Active",
      },
      {
        value: 'inactive',
        title: "Inactive",
      },
      {
        value: 'suspended',
        title: "Suspended",
      },
    ],
    };
  },
  computed: {
    inputRequired: (v) => !!v || "Required field",
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
    startDate () {
      let date = this.form.startAt;
      if (date) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
        let monthName = monthNames[parseInt(month)-1];
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${monthName} ${year} | ${hours}:${minutes}`;
      }
      return '';
    },
    endDate () {
      let date = this.form.endAt;
      if (date){
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
        let monthName = monthNames[parseInt(month)-1];
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day} ${monthName} ${year} | ${hours}:${minutes}`;
      }
      return '';
    },
    ...mapGetters("account", ["packages"]),
  },
  methods: {
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    editItem(){
      this.canEdit = !this.canEdit;
    },
    parsedDate: function (v){
      if (v) {
        const [datePart, timePart] = v.split(' | ');
        const [day, month, year] = datePart.split(' ');
        const [hours, minutes] = timePart.split(':');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = monthNames.indexOf(month);
        return new Date(year, monthIndex, day, hours, minutes);
      }
    },
    fetchAccountPackage() {
      let vm = this;
      this.isLoading = true;
      this.$store
          .dispatch(`account/${FETCH_ACCOUNT_PACKAGE}`, {
            id: this.accountId,
          })
          .then(
              (response) => {
                const { data } = response;
                this.info = data;
                let objItem = {
                  packageId: data.packageId,
                  name: data.name,
                  status: data.packageStatus,
                  isActive: data.isActive,
                  startAt: vm.parsedDate(data.startAt),
                  endAt: vm.parsedDate(data.endAt),
                };
                this.form = _.merge({}, objItem);
                this.isLoading = false;
              },
              (error) => {
                console.log(error);
              }
          );
    },
    fetchPackageNames: function () {
      this.$store
          .dispatch("account/" + FETCH_PACKAGE_DICTIONARY)
          .then(
              () => {
                this.isLoading = false;
              },
              () => {
                this.isLoading = false;
              }
          )
          .catch(() => {
            this.isLoading = false;
          });
    },
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { form } = this;
        this.$store
          .dispatch(`account/${UPDATE_ACCOUNT_PACKAGE}`, {
            data: form,
            id: this.accountId,
          })
          .then(
            (response) => {
              this.dataLoading = false;
              if (response.result) {
                this.errors = {};
                this.fetchPackageNames();
                this.fetchAccountPackage();
                EventBus.$emit(UPDATED_ACCOUNT_PACKAGE, 1);
              } else {
                this.errors = response.details;
              }
              this.editItem();
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.fetchPackageNames();
    this.fetchAccountPackage();
  },
};
</script>
