<template>
  <div>
    <router-link :to="backLink" class="text-body-2 text-decoration-none"
      >Back to Accounts page</router-link
    >
    <h1 v-if="!isLoading" class="outline--text">
      {{ accountInfo.name }}
    </h1>
    <h1 v-else class="outline--text">
    </h1>
    <account-package-form
      v-if="canManage"
      :account-id="id"
    ></account-package-form>
    <v-row>
      <v-col>
        <h3>Account package history</h3>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="packages"
      :item-class="rowClass"
      :items-per-page="pagination.rowPerPage"
      :server-items-length="pagination.rowPerPage"
      disable-pagination
      disable-filtering
      hide-default-footer
      :sort-by.sync="sort.sortBy"
      :sort-desc.sync="sort.desc"
    >
      <template v-slot:item.packageStatus="{ item }">
        <span v-if="item.packageStatus === 'active'" class="green--text">{{ item.packageStatus }}</span>
        <span v-else-if="item.packageStatus === 'pending'" class="purple--text">{{ item.packageStatus }}</span>
        <span v-else-if="item.packageStatus === 'suspended'" class="orange--text">{{ item.packageStatus }}</span>
        <span v-else>{{ item.packageStatus }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import acl from "@/mixin/acl";
import AccountPackageForm from "./account/item/AccountPackageForm";
import { FETCH_ACCOUNT_PACKAGES} from "@/store/modules/account/actions";
import {RESOURCE_ACCOUNTS_MANAGEMENT} from "../store/modules/admin/getters";
import {EventBus} from "../utils";
import {UPDATED_ACCOUNT_PACKAGE} from "../utils/events";
import {FETCH_ACCOUNT} from "@/store/modules/account/actions";
export default {
  name: "AccountPackagesPage",
  mixins: [acl],
  components: { AccountPackageForm },
  props: {
    id: {
      required: true,
    },
    packageId: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    balance: {
      type: String,
    },
  },
  data() {
    return {
      prevRoute: null,
      isLoading: false,
      packages: [],
      info: null,
      accountInfo: null,
      pagination: {
        page: 1,
        rowPerPage: 20,
      },
      sort: {
        sortBy: "id",
        desc: true,
      },
      count: 0,
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Package Name",
          value: "name",
        },
        {
          text: "Package Type",
          value: "packageType",
        },
        {
          text: "Status",
          value: "packageStatus",
        },
        {
          text: "Start Date",
          value: "startAt",
        },
        {
          text: "End Date",
          value: "endAt",
        },
        {
          text: "Updated",
          value: "updatedAt",
        },
        {
          text: "Created",
          value: "createdAt",
        },
      ],
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
    };
  },
  computed: {
    canManage: function () {
      return this.hasAccess([RESOURCE_ACCOUNTS_MANAGEMENT]);
    },
    pageCount() {
      return Math.ceil(this.count / this.pagination.rowPerPage);
    },
    limit() {
      return this.pagination.rowPerPage;
    },
    offset() {
      return (this.pagination.page - 1) * this.pagination.rowPerPage;
    },
    formattedSort() {
      let formatted = this.sort.sortBy;
      if (this.sort.desc) {
        return `-${formatted}`;
      }
      return formatted;
    },
    backLink() {
      return this.prevRoute && this.prevRoute.name === "AccountListPage"
        ? this.prevRoute
        : { name: "AccountListPage" };
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.fetchAccountPackages();
      },
    },
    sort: {
      deep: true,
      handler() {
        this.fetchAccountPackages();
      },
    },
  },
  methods: {
    rowClass(item) {
      // Example: Change background color based on a condition
      if (item.packageType === 'current') {
        return 'greenLighten';
      } else if (item.packageType === 'next') {
        return 'purpleLighten'
      }
      return 'grey--text text--darken-2';
    },
    fetchAccount() {
      this.isLoading = true;
      this.$store
          .dispatch(`account/${FETCH_ACCOUNT}`, {
            accountId: this.id,
          })
          .then(
              (response) => {
                const { data } = response;
                this.accountInfo = data;
                this.isLoading = false;
              },
              (error) => {
                console.log(error);
              }
          );
    },
    fetchAccountPackages() {
      this.isLoading = true;
      this.$store
        .dispatch(`account/${FETCH_ACCOUNT_PACKAGES}`, {
          accountId: this.id,
          limit: this.limit,
          offset: this.offset,
          sort: this.formattedSort,
        })
        .then(
          (response) => {
            const { count, data } = response;
            this.count = count;
            this.packages = data;
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    this.fetchAccount();
    this.fetchAccountPackages();
  },
  created() {
    EventBus.$on(UPDATED_ACCOUNT_PACKAGE, () => {
      this.fetchAccountPackages();
    });
  },
};
</script>
